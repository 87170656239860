.AppLoader {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.AppLoader .ImageContainer {
    background-image: url(../../../app/images/logo_wagx_web_login.png);
    background-repeat: no-repeat;
    padding: 5em 15em 5em 15em;
    background-position: center;
}