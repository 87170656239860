.GooglePlacesAutocompleteTextField .GooglePlacesAutocompleteDropdownContainer {
  position: absolute;
  z-index: 1000;
}
.GooglePlacesAutocompleteTextField .GooglePlacesAutocompleteDropdownContainer {
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 5px 0px;
  cursor: pointer;
  overflow: hidden auto;
  max-height: 300px;
  border-bottom: 1px solid rgb(234, 234, 234);
  text-align: left;
}
.GooglePlacesAutocompleteTextField .GooglePlacesAutocompleteDropdownContainer .GooglePlacesAutocompleteSuggestionItem {
  font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 60vw;
  padding: 6px 12px 7px;
  white-space: nowrap;
  background: none!important
}

.GooglePlacesAutocompleteGoogleLogo {
  vertical-align: middle;
  pointer-events: none;
  height: 20px;
}