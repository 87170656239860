.SelectFieldHeaderContentContainer{
  width: calc(100% - 34px); /* 34px -> 18px di multiSelectSelectionAll sommati ai 16px di padding di ms-ComboBox-header */
  float: right;
  position: absolute;
  background-color: white;
  z-index: 100;
}
.SelectFieldHeaderContentContainer .multiSelectSearch{
  width: calc(100% - 34px); /* Relativo a SelectFieldHeaderContentContainer */
  float: right;
}
.SelectFieldHeaderContentContainer .multiSelectSelectionAll{
  vertical-align: middle;
  height: 18px;
  width: 18px;
}
.SelectFieldHeaderContentContainer .textfield-border-bottom-only .ms-TextField-fieldGroup {
  border-left: none;
  border-right: none;
  border-top: none;
}

.SelectFieldHeaderContentContainer.noResult{
  width: 100%;
  position: static;
}
.SelectFieldHeaderContentContainer.noResult .multiSelectSearch{
  width: 100%;
}
.SelectFieldHeaderContentContainer.noResult .multiSelectSelectionAll{
  display: none;
}

.DisabledOption{
  opacity: 0.6;
  color: black;
  font-weight: normal;
}

.ms-Callout.ms-ComboBox-callout .ms-Callout-main{
  position: static!important;
}