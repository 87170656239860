
.DataTableTdIconClickable{
    cursor: pointer;
}
.DataTableTd{
    width:99%;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}