.wagxAceEditor.editor-fullscreen {
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  left: 0px;
  z-index: 1000;
}

.wagxAceEditor {
  border-bottom: 1px solid #ddd;
}