.textWithBorder {
  border-width: 1px;
  width: 14px;
  height: 14px;
  border-style: solid;
  font-size: smaller;
  text-align: center;
  font-weight: bold;
  vertical-align: super;
}

.DataTableTop .textWithBorder {
  text-align: center !important;
  vertical-align: text-bottom;
  display: inline-block;
}

.ms-textAlignCenter.dataTableTdMiddle32 .textWithBorder {
  margin-left: auto;
  margin-right: auto;
}

.ms-textAlignRight.dataTableTdMiddle32 .textWithBorder {
  margin-left: auto;
  margin-right: 0px;
}

.ms-textAlignLeft.dataTableTdMiddle32 .textWithBorder {
  margin-left: 0px;
  margin-right: auto;
}

.textWithBorder {
  border-width: 1px;
  width: 14px;
  height: 14px;
  border-style: solid;
  font-size: smaller;
  text-align: center !important;
  font-weight: bold;
  vertical-align: text-bottom;
  display: inline-block;
  margin-bottom: 2px;
}

.textWithBorder-totale {
  color: rgb(29, 92, 147);;
}

.textWithBorder-disabled {
  color: grey;
}

.textWithBorder-parziale {
  color: orange;
}

.textWithBorder-aperto {
  color: green;
}

.textWithBorder-verde {
  color: green;
}

.textWithBorder label
{
  padding: 0px;
  font-weight: normal;
  font-size: x-small;
}

.textWithBorder-disabled label{
  padding: 0px;
    font-weight: normal;
    font-size: x-small;
    color:grey;
}

.textWithBorder-parziale label{
  padding: 0px;
  font-weight: normal;
  font-size: x-small;
  color: orange; 
}

.textWithBorder-aperto label{
  padding: 0px;
  font-weight: normal;
  font-size: x-small;
  color: green;
}