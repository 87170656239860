body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  overflow-x: hidden;
}

.full-height {
  height: 100% !important;
}

html,
#root,
.root-51,
.App {
  height: 100%;
}

.AppContentBody {
  height: calc(100% - 5em);
}

.cicostool-splitter .wagxSplitter {
  height: calc(100vh - 5em) !important;
  width: calc(100% - 63px) !important;
}

.n-sinistro-bold div {
  font-weight: 700;
  color: #1d5c93;
}


.GenericInputLabelTitle {
  width: 150px;
  display: inline-block !important;
}

.GenericInputLabelValue {
  text-align: right;
  display: inline-block !important;
}