.PrimaIstruttoriaList {
  direction: ltr;
}
.PrimaIstruttoriaList .callout-button {
  background-color: white;
  width: 100%;
  color: black;
}
.PrimaIstruttoriaList .callout-button:hover {
  background-color: #f4f4f4;
}

.PiSinistriPartiFormGroup .groupedInputClass div {
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
}