.combo-box-with-render-label {
  width: 100% !important;
}

.combo-box-with-render-label.invalid div.ms-ComboBox {
  border-color: rgb(168, 0, 0) !important;
}

.combo-box-with-render-label.read-only .ms-ComboBox {
  background-color: white;
}

.combo-box-with-render-label.read-only .ms-ComboBox input {
  background-color: white;
  color: black;
}