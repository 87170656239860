.WagxExpandPanel {
  display: flex;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  flex-flow: column;
  -webkit-flex-flow: column;
  /* flex-direction: column; */
}

.WagxExpandPanelHeader {
  display: inline-flex;
  width: 100%;
  align-items: center;
}

.WagxExpandPanelHeader .expand-button {
  float: left;
  margin-left: auto;
}

.ClaimDeskMainPage .WagxExpandPanel .expand-button i, .PiSinistriTestataFormGroup .expand-button i {
  font-size: 0.6em;
}

.WagxExpandPanelHeaderLeftButton {
  justify-content: flex-end;
  flex-direction: row-reverse;
  display: inline-flex;
  width: 100%;
  align-items: center;
}

.WagxExpandPanelHeaderLeftButton .expand-button {
  width: 24px;
}

.PiSinistriTestataFormGroup .expand-button {
  width: 30px;
}

.WagxExpandPanelBody {
  transition: max-height 0.15s ease-out;
}

.WagxExpandPanelBody .DataTable .ms-ScrollablePane--contentContainer {
  overflow-x: hidden !important;
  overflow-y: auto !important;
  margin-left: 40px;
}