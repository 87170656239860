button.wagx-link {
  background: none;
  border: none;
  padding: 0;
  /*font-family: arial, sans-serif;*/
  /*color: #069;*/
  /*text-decoration: underline;*/
  cursor: pointer;
  text-align: left;
  font-size: 12px;
}