.SpinnerContentContainer {
  display: block;
  text-align: center;
  position: relative;
}

.SpinnerContainer {
  position: relative;
  width: 7.5%;
  margin-top: 3%;
  display: inline-block;
  margin-bottom: 2%;
}

.SpinnerContainer:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.SpinnerContainer .ms-Spinner {
  display: block!important;
}

.SpinnerContainer .ms-Spinner-circle {
  border-width: 5px;
  position: absolute;
  width: 100%;
  height: 100%;
}