.confirm-modal .confirm-modal-container {
  width: auto;
  max-width: 80vw;
  min-width: 325px;
  max-height: 80vh;
  border-radius: 15px;
}

.confirm-modal .confirm-modal-container.is-request-in-progress {
  width: 50px;
  height: 50px;
  min-width: 0;
}

.confirm-modal .confirm-modal-container.is-request-in-progress .ms-Spinner {
  height: 100%;
}

.confirm-modal .confirm-modal-scrollable-content {
  height: 100%;
  width: 100%;
}

.confirm-modal .confirm-modal-header {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(29, 92, 147);
  color: white;
  font-weight: bold;
  font-size: large;
}

.confirm-modal .confirm-modal-header label {
  margin-left: 20px;
  margin-right: 20px;
}

.confirm-modal .confirm-modal-body {
  /* Altezza data sottraendo margin e height di "header" e "footer" */
  height: calc(100% - 120px);
  display: flex;
  align-items: center;
  margin: 20px 0px 0px 20px;
  gap: 10px;
}

.confirm-modal .confirm-modal-body .message-list-container {
  max-height: calc(80vh - 120px);
  padding-left: 1px;
  padding-right: 20px;
  text-align: left;
  overflow-y: auto;
  font-size: medium;
}

.confirm-modal .confirm-modal-body .message-list-container ul {
  padding-left: 15px;
}

.confirm-modal .confirm-modal-body .icon-container>div {
  background: #a8cf58;
  border-radius: 50%;
  width: 42px;
  height: 42px;
  border: 8px solid #8aac4e;
  text-align: center;
  line-height: 42px;
}

.confirm-modal .confirm-modal-body .icon-container .question {
  background: #779dbe;
  border-color: #1d5c93;
}

.confirm-modal .confirm-modal-body .icon-container .question i {
  color: #ffffff;
  font-size: 50px;
  margin-left: -2.5px;
  margin-top: -2px;
}

.confirm-modal .confirm-modal-footer {
  height: 50px;
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.confirm-modal .confirm-modal-footer button {
  min-width: 150px;
}

.confirm-modal .confirm-modal-footer button:first-child {
  margin-left: 20px;
}

.confirm-modal .confirm-modal-footer button:last-child {
  margin-right: 20px;
}

.confirm-modal .confirm-modal-footer button.primary {
  background-color: rgb(29, 92, 147);
  color: white;
}

.confirm-modal .confirm-modal-footer button.primary:hover {
  background-color: rgb(16, 110, 190);
  color: white;
}