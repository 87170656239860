.DataTableToolbar .filterBar {
  background-color: rgba(28, 152, 213, 0.1);
  border-bottom: solid 1px #edefee;
  padding: 10px;
  padding-bottom: 5px;
}

.DataTableToolbar .filterBox {
  padding: 5px;
}

.DataTableToolbar .filterBox .ms-TextField-fieldGroup,
.DataTableToolbar .filterBox .ms-TextField-fieldGroup input,
.DataTableToolbar .filterBox .ms-ComboBox {
  border-radius: 10px;
}

.DataTableToolbar .DataTableToolbarOutherGrid {
  padding: 0 8px;
}

.DataTableToolbarButton {
  float: right;
}

/* UTILIZZATA PER ALLINEARE VERTICALMENTE LE INPUT "RANGE" NEI FILTRI DI DATATABLE */
.DataTableToolbar .filterBox .ms-Grid-col:nth-of-type(odd) {
  padding-right: 5px;
}

.DataTableToolbar .filterBox .ms-Grid-col:nth-of-type(even) {
  padding-left: 5px;
}