.WagxAutocomplete.invalidBox div {
    border-color: rgb(168, 0, 0);
}

.WagxAutocomplete.warningBox div {
  border-color: orange;
}

.WagxAutocomplete .ms-BasePicker-text {
    font-family: "Segoe UI", "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, "Roboto", "Helvetica Neue", sans-serif;
    padding-left: 12px;
    background-color: rgb(255, 255, 255);
    display: block;
    height: 32px;
    text-align: left;
    min-width: 0px;
}
.WagxAutocomplete .ms-BasePicker-text .ms-TagItem {
    font-size: 14px;
    background: transparent none repeat scroll 0% 0%;
    width: 100%;
    outline: currentcolor none 0px;
    color: black;
    max-width: none;
}
.WagxAutocomplete .ms-BasePicker-text span {
    margin: 0;
    width: 100%;
}
.WagxAutocomplete .ms-BasePicker-text input {
    padding-left: 0px;
    background: transparent none repeat scroll 0% 0%;
    width: 90%;
}
.WagxAutocomplete .ms-BasePicker-text input::-ms-clear {
    display: none;
}
.WagxAutocomplete .ms-BasePicker-text .ms-TagItem button {
    display: block;
    right: -1px;
    color: black;
    background: none!important;
}

.ms-Suggestions .ms-Suggestions-none {
    opacity: 0.6;
    color: black;
    font-weight: normal;
}