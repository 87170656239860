.invalidBox div{
    border-color: rgb(168, 0, 0);
}

.warningBox div{
  border-color: orange;
}
/* Quando un datepicker è utilizzato come campo di ricerca nelle datatable, veniva aggiunto un doppio margine che disallineava gli input. Questa classe risolve il problema*/
.filterBox .DatePickerContainer.filterBox {
  padding: 0;
}